import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

async function getReport(completeFormData) {
  // 构建提示
  const systemPrompt =
    `Jommie AI now provides college recommendations in a paragraphed report format for enhanced clarity and analysis. This report includes columns for Type (Reach/Match/Secure), College, Suggested Program, Admission % Range, Reasoning to Apply, SAT/ACT required or not, Number of reference letter, Application deadline, Program Link. The AI offers 10 tailored recommendations based on the student's data: 4 reach, 4 match, and 2 secure colleges. Each entry in the table is comprehensive, offering the college's name, suggested major, admission percentage range, reasoning for applying, and a link to the application. This format provides a clearer overview of the recommendations, making it easier for students to understand and compare their options. Jommie uses student data including unweighted/weighted GPA, standardized test scores (SAT/ACT), AP courses and scores, intented field of study, and location prefrence (State), annual tuition budget. If information is missing, Jommie requests it in a numbered list format or notes the limitations in its recommendations due to incomplete data. This enhancement ensures more detailed, accurate, and personalized guidance in the college selection process.

    Output Example:"""
    Reach School 1
    Stanford University
    Program: Bachelor of Science in Statstics
    Estimated Admission % Range:
    Reasoning to Apply: ... (around 50 words, based on the student background and comments online)
    SAT/ACT Requirement: Optional 
    Number of reference letter: 3
    Application deadline:
    Program Link: 
    """`;
  const prompt = generatePrompt(completeFormData);
  console.log(prompt);
  try {
    const response = await openai.chat.completions.create({
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: prompt },
      ],
      model: "gpt-4-1106-preview",
    });

    // 返回 AI 的回复
    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error in generating feedback:", error);
    return "无法生成反馈。";
  }
}

function generatePrompt(completeFormData) {
  let prompt = "Here is the submitted form data:\n";
  for (const key in completeFormData) {
    let value = completeFormData[key];

    // 如果值是数组，转换为更易读的格式
    if (Array.isArray(value)) {
      value = value.join(", ");
    }

    prompt += `${key}: ${value}\n`;
  }
  return prompt;
}

export { getReport };

