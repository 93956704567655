<template>
  <router-view/>
</template>

<style>
button {
  /* 按钮样式，例如： */
  background-color: #1b70b1;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: 1px black;
  border-radius: 4px;
}

button:hover {
  background-color: #4ba2e4;
}

input {
  border: none;
  border-bottom: 2px solid #000; /* 底部边框 */
  font-size: 16px;
  padding: 10px 0;
  width: 300px; /* 或根据需要调整宽度 */
  outline: none; /* 移除焦点时的轮廓 */
}

input:focus {
  border-bottom-color: blue; /* 聚焦时改变边框颜色 */
}
</style>