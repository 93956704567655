<template>
  <div class="home">
    <img alt="logo" src="@/assets/logo.png" class="logo">
    <div class="headline">
  Start <span class="typewriter">Making</span><br>Your Report
</div>
    <router-link to="/form">
      <button class="start-button">Get Started</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.logo {
  max-width: 150px; /* 或者其他适合的尺寸 */
  margin-bottom: 20px;
}

.headline {
  font-size: 54px; /* 调整字体大小 */
  margin-bottom: 20px;
}


.typewriter {
  color: #1b70b1;
  vertical-align: bottom;
  margin: 0 5px;
  display: inline-block;
  border-right: 2px solid black; /* 光标效果 */
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 0.5s steps(6) 1s 1 normal both,
             blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 3.3em; } /* 根据单词长度调整宽度 */
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

</style>
