<template>
  <div v-if="!showDetailForm">
    <div class="form-page">
      <h1>Choose your field of Study</h1>
      <form @submit.prevent="goToNextForm">
        <div class="input-container">
          <input
            type="text"
            v-model="fieldOfStudy"
            placeholder="E.g. Environmental science"
            required
          />
        </div>
        <button type="submit" class="continue-button">Continue</button>
      </form>
    </div>
  </div>
  <DetailFormComponent
    v-if="showDetailForm"
    :fieldOfStudy="fieldOfStudy"
  />
</template>

<script>
import DetailFormComponent from "@/components/DetailFormComponent.vue";


export default {
  name: "FormView",
  components: {
    DetailFormComponent, // 注册 DetailFormComponent
  },

  data() {
    return {
      fieldOfStudy: "", // 这里存储输入的内容
      showDetailForm: false, // 控制是否显示 DetailFormComponent
    };
  },
  methods: {
    goToNextForm() {
      // 这里可以添加跳转到下一个表单的逻辑
      console.log(this.fieldOfStudy); // 示例：在控制台打印输入的内容
      this.showDetailForm = true;
    },
  },
};
</script>

<style scoped>
.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.input-container {
  margin-bottom: 20px;
}
</style>
