<template>
  <div class="report-view" ref="reportView">
    <h1>Your College Report</h1>
    <div v-if="report">
      <h2>Report:</h2>
      <div v-html="renderedReport"></div>
    </div>
    <div v-else>
      <div class="loading-bar">
        <breeding-rhombus-spinner
          :animation-duration="2000"
          :size="65"
          color="#4ba2e4"
        />
      </div>
    </div>
  </div>
  <div v-if="report">
    <div class="button-container">
      <button @click="exportPdf">Export as PDF</button>
    </div>
  </div>
</template>

<script>
import { getReport } from "@/services/ReportService";
import { BreedingRhombusSpinner } from "epic-spinners";
import MarkdownIt from "markdown-it";
import mila from "markdown-it-link-attributes";
import html2pdf from "html2pdf.js";

export default {
  name: "ReportView",
  components: {
    BreedingRhombusSpinner, // 注册 DetailFormComponent
  },

  data() {
    return {
      report: null, // 用于存储报告的文本
      completeFormData: null, // 存储从 localStorage 获取的数据
      markdownParser: new MarkdownIt().use(mila, {
        attrs: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
      }),
    };
  },
  async created() {
    this.getFormData();
    if (this.completeFormData) {
      await this.fetchReport();
    }
  },
  computed: {
    renderedReport() {
      return this.markdownParser.render(this.report);
    },
  },

  methods: {
    async fetchReport() {
      if (this.completeFormData) {
        this.report = await getReport(this.completeFormData);
        console.log("Report Loaded:", this.report); // 调试日志
      }
    },
    getFormData() {
      const formDataString = localStorage.getItem("completeFormData");
      if (formDataString) {
        this.completeFormData = JSON.parse(formDataString);
        localStorage.removeItem("completeFormData");
      }
    },
    exportPdf() {
      // 确保元素存在
      this.$nextTick(() => {
        const element = this.$refs.reportView;
        if (element) {
          const options = {
            margin: 10,
            filename: "my-report.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };
          html2pdf(element, options);
        } else {
          console.error("Element to print not found!");
        }
      });
    },
  },
};
</script>

<style scoped>
.report-view {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  color: #4ba2e4;
  margin-top: 20px;
}

p {
  color: #666;
  line-height: 1.6;
}

/* 调整加载动画样式 */
.loading-bar {
  display: flex;
  justify-content: center;
  padding: 12rem;
}

.button-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 20px; /* 如有需要，添加一些上边距 */
}

</style>
