<template>
  <div class="detail-form">
    <h1>Cooking my college report</h1>
    <form @submit.prevent="submitForm">
      <div class="question">
        <label for="unweighted-gpa">What is your unweighted GPA?</label>
        <input
          type="text"
          id="unweighted-gpa"
          v-model="formData.unweightedGPA"
          required
          placeholder="E.g. 3.55"
        />
      </div>

      <div class="question">
        <label for="weighted-gpa"
          >What is your weighted GPA? (with AP/Honour)</label
        >
        <input
          type="text"
          id="weighted-gpa"
          v-model="formData.weightedGPA"
          required
          placeholder="E.g. 4.35"
        />
      </div>

      <div class="question">
        <label>What is your highest SAT/ACT Score?</label>
        <div class="sub-question">
          <label for="sat-score">SAT:</label>
          <input type="text" id="sat-score" v-model="formData.satScore" placeholder="E.g. Reading: 610, Math: 610"/>
        </div>
        <div class="sub-question">
          <label for="act-score">ACT:</label>
          <input type="text" id="act-score" v-model="formData.actScore" placeholder="E.g. 31"/>
        </div>
      </div>

      <div class="question">
        <label>What is your AP/Honour Score</label>
        <div
          v-for="(score, index) in formData.apHonourScores"
          :key="index"
          class="sub-question"
        >
          <input type="text" v-model="formData.apHonourScores[index]" placeholder="E.g. AP Chemistry - 5"/>
          <button type="button" @click="removeScore(index)">Remove</button>
        </div>
        <button type="button" @click="addScore">Add Score</button>
      </div>

      <div class="question">
        <label for="school-type"
          >Are you applying to private/public school?</label
        >
        <input
          type="text"
          id="school-type"
          v-model="formData.schoolType"
          required
          placeholder="E.g. Both Private and Public"
        />
      </div>

      <div class="question">
        <label>What state are you applying to?</label>
        <div
          v-for="(state, index) in formData.applyingStates"
          :key="'state-' + index"
          class="sub-question"
        >
          <input type="text" v-model="formData.applyingStates[index]" placeholder="E.g. California"/>
          <button type="button" @click="removeState(index)">Remove</button>
        </div>
        <button type="button" @click="addState">Add State</button>
      </div>

      <div class="question">
        <label for="tuition-budget"
          >What is your tuition budget per year?</label
        >
        <input
          type="text"
          id="tuition-budget"
          v-model="formData.tuitionBudget"
          required
          placeholder="E.g. $ 30,000"
        />
      </div>

      <button type="submit">Get Report</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "DetailFormComponent",
  props: {
    fieldOfStudy: String,
  },

  data() {
    return {
      formData: {
        unweightedGPA: "",
        weightedGPA: "",
        satScore: "",
        actScore: "",
        apHonourScores: [],
        schoolType: "",
        applyingStates: [],
        tuitionBudget: "",
      },
    };
  },
  methods: {
    addScore() {
      this.formData.apHonourScores.push("");
    },
    removeScore(index) {
      this.formData.apHonourScores.splice(index, 1);
    },
    addState() {
      this.formData.applyingStates.push("");
    },
    removeState(index) {
      this.formData.applyingStates.splice(index, 1);
    },

    submitForm() {
      const completeFormData = {
        fieldOfStudy: this.fieldOfStudy,
        ...this.formData,
      };
      console.log("Complete Form Data:", completeFormData);

      localStorage.setItem('completeFormData', JSON.stringify(completeFormData));
      this.$router.push({ path: '/report' });
    },
  },
};
</script>

<style scoped>
.detail-form {
  max-width: 600px; /* 设置最大宽度 */
  margin: auto; /* 居中显示 */
  padding: 20px;
}

.detail-form h1 {
  text-align: center;
  margin-bottom: 30px;
}

.question {
  margin-bottom: 20px;
}

.question label {
  display: block; /* 使标签成为块级元素 */
  margin-bottom: 10px; /* 为标签和输入框之间添加间隔 */
}

.sub-question {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.sub-question button {
  padding: 5px 10px;
  font-size: 0.8rem;
  background-color: #1d7fc1;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.sub-question button:hover {
  background-color: #4ba2e4;
}
</style>
